@import '~@cdx/styles/settings/_colors.scss';

$text-color: #212121;
$border-color: #707070;
$light-border-color: #cccccc;
$highlight-color: #f9d4d7;
$disabled-selection: #b0b0b0;

$risk-bar: #001750;
$exact-risk: #de2839;
$high-risk: #f89324;
$medium-risk: #ffd90b;
$low-risk: #e0e0e0;

$ocean-blue-dark: #5e33bf;
$ocean-blue-medium: #b19de1;
$ocean-blue-hover: #dfd6f2;
$ocean-blue-light: #eee7f8;

$clarivate-0: #5e33bf;
$clarivate-green: #16ab03;

// TODO figure out how to extract this from the theme with ag-param
$ag-row-border-color: #e2e2e2;

$red-light: #fadcdf;
$red-dark: #de2839;
$orange-light: #feeedb;
$orange-dark: #d06e06;
$yellow-light: #fff9d7;
$yellow-dark: #a88f00;
$grey-light: #e0e0e0;
$grey-dark: #707070;

$checkbox-border-color: rgba(0, 0, 0, 0.54);
