/* stylelint-disable*/

$___fontRoot: '~@cdx/styles/fonts/';
$___iconRoot: '~@cdx/styles/icons/';

@use '@cdx/theme-angular-material' as cdx;
@use '@cdx/ngx-branding/header/theme' as header;
@use '@cdx/ngx-branding/footer/theme' as footer;
@use '@cdx/theme-ag-grid';
@use '@angular/material' as mat;

@import 'theme';
@import 'colors';
@import 'variables';
@import 'quill/dist/quill.snow.css';
@import 'quill/dist/quill.core.css';
@import 'gridstack/dist/gridstack.min.css';
@import 'gridstack/dist/gridstack-extra.min.css';

@include cdx.default;
@include header.theme(cdx.$cdx-theme);
@include footer.theme(cdx.$cdx-theme);

$my-custom-typography: mat.define-typography-config(
  $font-family: 'Open Sans',
);
@include mat.core($my-custom-typography);

.cdx-but-menu {
  color: $cdx-color-gray-900 !important;

  &:hover {
    background-color: $cdx-gray-light;
  }
}

.app-color-picker .preview {
  margin: 2px;
  display: inline-block;
  box-sizing: border-box;
  border-radius: 100%;
  background: white;
  cursor: pointer;
  padding: 3px;
  box-shadow: none !important;
}

.app-color-picker .circle {
  height: 1.5rem !important;
  width: 1.5rem !important;
  margin-left: 0px;
}
.app-color-picker:focus-visible {
  outline: 2px solid #5e33bf;
}

.app-color-picker .preview .preview-background {
  background-image: none !important;
}

app-full-text .mat-tab-body-content {
  height: auto !important;
}

app-deep-dive {
  .cdx-but-menu {
    line-height: 0px !important;
  }
}

// disable overscroll on boundaries
html,
body {
  overflow: hidden;
  overscroll-behavior: none;
}
.mat-checkbox-label {
  margin-left: 0.35rem;
}

.mat-menu-item {
  font-size: 0.875rem;
  line-height: 20px;
}

.mat-tooltip {
  font-size: 0.75rem;
  background-color: black;
  padding: 8px 16px !important;
  line-height: 1.5;
}

.ag-tooltip {
  background-color: black !important;
  color: white !important;
}

app-all-threats-with-bulk-actions,
app-content-threats-table {
  height: 100%;
  .center-text-in-cell {
    display: flex;
    align-items: center;
    .ag-cell-wrapper {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  .split-view-enabled .ag-theme-cdx .ag-row-focus {
    background-color: #5e33bf;
    color: white !important;

    mat-icon,
    .ag-icon,
    .ag-cell-value:before,
    .file-download-empty,
    .open-in-new {
      color: white !important;
    }
    .ag-checkbox-input-wrapper {
      background-color: white;
      border-color: white !important;
      box-shadow: none;
      .ag-checked {
        color: white !important;
      }
    }
  }

  .highlights em {
    color: #212121;
  }

  .ag-row.unreviewed .reviewed-indicator-container .ag-cell-value:before {
    color: white;
  }

  .ag-theme-cdx
    .ag-side-bar
    .ag-column-select-header
    .ag-checkbox-input-wrapper.ag-indeterminate::after {
    color: #5e33bf !important;
  }
}

app-export-admin {
  display: block;
  height: calc(100vh - 4rem);
}

.initiate-input .mat-form-field-flex {
  padding: 0.875rem !important;
}

.cdk-global-scrollblock {
  overflow: hidden;
}

.hide-comment-dialog {
  opacity: 0 !important;
  transition: opacity 0.3s ease;
  display: none !important;
}

.ag-row-group {
  background-color: $cdx-color-gray-200;
}

app-content-threats-table {
  app-select-all-header {
    margin-left: 25px;
    .mat-checkbox {
      margin-left: 0.7rem !important;
    }
  }
  .mat-checkbox-inner-container {
    height: 18px;
    width: 18px;
  }
  .ag-header-cell .ag-focus-managed {
    padding-left: 0.5rem;
  }
}

app-all-threats-with-bulk-actions .mat-checkbox-inner-container {
  height: 18px;
  width: 18px;
}

.ag-theme-cdx {
  .ag-ltr {
    .ag-has-focus {
      .ag-full-width-row.ag-row-focus .ag-cell-wrapper.ag-row-group {
        border: none;
        padding-left: 1.125rem;
      }
    }
  }

  .ag-checkbox-input-wrapper {
    font-size: 18px;
    width: 18px;
    height: 18px;

    &:not(.ag-checked)::after {
      color: $checkbox-border-color !important;
    }
  }
}

.poca-score-indicator-all-threats {
  white-space: pre-line;
}

app-delete-search {
  align-items: center;
  display: flex;
}

app-owner-deep-dive,
app-litigation-deep-dive,
app-owners-deep-dive {
  .right h2 {
    line-height: 1.595rem !important;
  }

  .ag-row.unreviewed .reviewed-indicator-container:before {
    bottom: 0.109rem;
    color: ag-param(material-primary-color);
    content: '\2B24';
    font-size: 0.438rem;
    margin-right: 0.438rem;
    position: relative;
  }
}

app-all-threats,
app-all-threats-with-bulk-actions,
app-owner-results,
app-content-threats-table {
  .tool-panel-disabled {
    pointer-events: none;
    .ag-checkbox-input-wrapper.ag-checked::after {
      color: #b0b0b0;
    }
    .ag-icon {
      color: #b0b0b0;
    }
  }
  .ag-row.unreviewed .reviewed-indicator-container .ag-cell-value:before {
    bottom: 0.109rem;
    color: ag-param(material-primary-color);
    content: '\2B24';
    font-size: 0.438rem;
    margin-right: 0.438rem;
    position: relative;
  }
  .ag-row.unreviewed .reviewed-indicator-container .ag-cell-value .name {
    font-weight: 700;
  }
  .mat-badge-content {
    color: white !important;
  }
}

app-owner-results {
  .ag-row.unreviewed .reviewed-indicator-container .ag-cell-value {
    font-weight: 700;
  }
}

app-all-threats,
app-all-threats-with-bulk-actions,
app-content-threats-table {
  .legal-reputation-icon {
    background-color: red;
    border-radius: 50%;
    color: white;
    display: inline-block;
    font-size: 1rem;
    font-weight: bold;
    height: 1.3rem;
    line-height: 1.3rem;
    text-align: center;
    width: 1.3rem;
  }
  .circle {
    font-size: 1.04rem;
    height: 1rem;
    margin-right: 0.1875rem;
    vertical-align: middle;
    width: 1rem;
  }

  .ag-theme-cdx .ag-menu-option-text {
    padding-left: 0.125rem !important;
  }

  app-grace-period-indicator {
    .grace-period-indication {
      float: none !important;
      .mat-icon {
        margin-top: -0.1rem !important;
      }
    }
  }

  app-tile,
  app-comments-renderer,
  app-actions,
  app-actions-renderer {
    lib-threat-comment {
      .material-icons-outlined {
        font-size: 1.25rem !important;
      }
      .mat-icon {
        font-size: 1.25rem;
        height: 1rem;
        width: 1rem;
      }
      .mat-icon-button {
        width: 2rem;
        height: 2rem;
        padding-right: 0.375rem;
        line-height: 0;
      }
      .mat-badge-medium .mat-badge-content {
        margin-top: 0.4rem;
        margin-right: 0.3rem;
        width: 0.75rem;
        height: 0.75rem;
        line-height: 0.8rem;
        font-weight: 600;
        font-size: 0.45rem;
        font-family: Open Sans;
        top: -0.6875rem !important;
        right: -0.8125rem !important;
      }
      .mode-comment {
        color: #888888 !important;
      }
      .mat-icon-button .mat-icon {
        line-height: 1.1875rem;
      }
    }
  }
}

.verbal-header-text {
  background: #e6e6e6;
}

app-top-threats {
  --ag-header-cell-hover-background-color: $cdx-color-neutral-50;

  .verbal-header-text {
    --ag-header-cell-hover-background-color: #e6e6e6;
  }

  .ag-row-level-1:not(.hasOneChild) {
    .trademark-name {
      padding-left: 30px;
    }
  }

  .ag-root-wrapper-body.ag-layout-normal {
    height: 100%;
  }
}

// ag-grid overrides
.centered-header-text .ag-cell-label-container .ag-header-cell-label {
  justify-content: center;
}

.ag-header-group-cell-label {
  justify-content: center;
}

.centered-cell-content {
  text-align: center;
}

.class-with-matches {
  margin-bottom: 0.875rem;

  .goods-matched-segments {
    margin-bottom: 0.438rem;
    word-break: break-word;
  }
}

.highlights {
  em {
    font-style: normal;
    text-decoration: underline;
    text-decoration-thickness: 0.0625rem;
    opacity: 1;
  }

  .NORMAL {
    text-decoration: none;
  }

  .EXACT {
    background-color: $red-light;
    text-decoration-color: $red-dark;
  }

  .HIGH {
    background-color: $orange-light;
    text-decoration-color: $orange-dark;
  }

  .MEDIUM {
    background-color: $yellow-light;
    text-decoration-color: $yellow-dark;
  }

  .LOW {
    background-color: $grey-light;
    text-decoration-color: $grey-dark;
  }
}

.cdx-header-product-logo {
  margin-top: 0.875rem !important;
}

app-navigate-dialog {
  .mat-button-wrapper {
    display: block;
    width: 100%;
  }
}

//TODO can be removed once risk panel component is moved to brand context components and removed from bla frontend
// put the icon in the center of the FAB
// unsure of what is interfering with the default material behavior
app-risk-panel {
  .mat-mini-fab .mat-button-wrapper {
    line-height: unset;
    padding: 0;
    padding-bottom: 0.263rem;

    mat-icon {
      height: unset;
    }
  }
}

// hr cdx override
hr {
  border-top-color: $cdx-color-neutral-300 !important;
}

// center the remove icon in the chip. CDX value likely getting in the way
mat-icon.mat-chip-remove {
  height: auto !important;
}

.initiate-inputs {
  .mat-form-field-should-float:not(.mat-focused) .mat-form-field-outline {
    height: 66px;
    color: white !important;
  }

  .mat-form-field-should-float.mat-focused .mat-form-field-outline {
    height: 66px;
    color: #914410 !important;
  }

  .mat-form-field-should-float.mat-form-field-invalid .mat-form-field-outline {
    height: 66px;
    color: #de2839 !important;
  }
}

.mat-checkbox {
  &.cdk-focused {
    .mat-checkbox-frame {
      box-shadow: none;
    }
  }
}

.legend {
  &.selected {
    font-weight: bold;
  }
}

// cdx does this weird transition for some elements
a,
input,
button,
select,
textarea {
  transition: none !important;
}

.highcharts-container,
.highcharts-container svg {
  width: 100% !important;
}

.mat-form-field-wrapper {
  margin: 0 !important;
  padding-bottom: 1.5rem;
  .mat-form-field-flex {
    margin: 0 !important;
  }
}

app-number-picker {
  .mat-form-field-infix,
  .mat-form-field-appearance-standard .mat-form-field-flex,
  .mat-form-field.mat-form-field-type-mat-input {
    border: none;
    padding: 0;
    text-align: center;
    width: 2.45rem;
  }

  .mat-form-field-underline,
  .mat-form-field-subscript-wrapper {
    display: none;
  }

  .mat-button-wrapper {
    position: relative;
    top: -0.3rem;
  }

  .mat-form-field.mat-form-field-type-mat-input .mat-input-element {
    font-size: 1.006rem;
    margin-top: 0 !important;
    position: relative;
    top: -0.1rem;
  }
}

app-package-select {
  .select-package {
    .mat-form-field {
      height: 61px;
    }
    .mat-form-field-flex {
      .mat-select-arrow {
        position: relative;
        top: 0.2rem;
      }
      .mat-select.mat-select-invalid .mat-select-arrow {
        color: black !important;
      }
    }

    .mat-form-field-infix {
      border-top: 0.263rem solid transparent !important;
      height: 3.625rem !important;
    }

    .mat-form-field-appearance-outline .mat-form-field-infix {
      border-top: 0 !important;
      padding: 0.5rem 0rem 1rem 0rem;
      width: 15rem;
    }

    .mat-form-field-appearance-outline .mat-form-field-outline {
      top: 0 !important;
    }
  }
}

.mat-form-field-should-float.mat-form-field-invalid
  .mat-form-field-outline
  .mat-form-field-outline-start,
.mat-form-field-should-float.mat-form-field-invalid
  .mat-form-field-outline
  .mat-form-field-outline-end {
  border-width: 1px !important;
}

.mat-autocomplete-panel {
  .mat-optgroup-label {
    color: black !important;
  }

  .mat-option-text {
    font-size: 0.875rem !important;
  }
}

input.mat-chip-input {
  margin: 0 !important;
}

.graph-part-selected {
  opacity: 1 !important;
}

.graph-part-not-selected {
  opacity: 0.3 !important;
}

app-market-multiselect-autocomplete {
  mat-chip-list {
    .mat-chip-list-wrapper {
      .mat-standard-chip {
        margin-left: 0;
      }
    }
  }
}

app-goods-and-services-input {
  .mat-chip-ripple {
    position: relative;
  }
}

app-markets {
  input {
    padding-left: 0 !important;
  }
  mat-tab-header {
    border: none !important;
  }
  .mat-tab-label-content {
    color: #212121 !important;
    font-weight: bold;
    opacity: 1 !important;
  }

  .mat-tab-label {
    opacity: 1;
  }

  .mat-form-field-appearance-outline .mat-form-field-flex {
    padding: 0 1rem 0 1rem;
  }
}

app-market-chips {
  max-height: 100% !important;
}

footer.cdx-footer {
  padding: 1rem 2rem;
}

.cdx-footer__content {
  gap: 1rem !important;
  column-gap: 4rem !important;
  grid-auto-flow: column;
  grid-template-columns: unset !important;
  white-space: nowrap;
}

@media (max-width: 1236px) {
  .cdx-footer__content {
    grid-auto-flow: unset;

    grid-template-columns: repeat(
      auto-fit,
      clamp(7.5rem, 7.5rem + 4.5 * (100vw - 48rem) / 37, 10rem)
    ) !important;
  }
}

app-goods-case-law-prediction-score {
  display: block;
}

section.scores {
  padding: 0;

  .verbal-similarity-card {
    padding: 0 1rem 0 0;
  }
}

.scores {
  .verbal-similarity-card {
    display: block;
    margin-bottom: 1.35rem;
    flex-grow: 1;
    padding: 0 1rem;

    mat-card {
      min-width: 200px;

      mat-card-header {
        height: 100px;
      }
    }
  }

  lib-poca-score-risk-panel {
    padding: 0 !important;
  }
}

.side {
  .verbal-similarity-card {
    display: block;
    margin-bottom: 1.35rem;
  }

  .goods-case-law {
    display: block;
    margin-bottom: 1.7rem;
  }
}

.mat-select-panel-wrap {
  flex-basis: 96% !important;
  margin-left: 0.438rem;
  margin-top: 1.9rem;
}

.common-panel {
  margin-top: 0.4375rem;
}

app-price-overview-dialog {
  mat-dialog-content {
    max-height: 78vh !important;
  }
}

app-purchase-package {
  .mat-card .mat-divider-horizontal {
    position: inherit;
  }

  .mat-divider {
    border-top-style: dotted;
    border-top-width: 0.131rem;
  }

  .package-name {
    .mat-form-field-wrapper {
      margin-top: -0.25rem !important;
    }

    .mat-form-field-appearance-outline .mat-form-field-infix {
      padding: 0.4rem 0rem 0.7rem 0rem;
    }

    .mat-form-field-appearance-outline
      .mat-form-field-outline-thick
      .mat-form-field-outline-start,
    .mat-form-field-appearance-outline
      .mat-form-field-outline-thick
      .mat-form-field-outline-end,
    .mat-form-field-appearance-outline
      .mat-form-field-outline-thick
      .mat-form-field-outline-gap {
      border-width: 1px !important;
    }

    .mat-form-field-appearance-outline .mat-form-field-label {
      margin-top: -0.5rem;
    }

    .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float
      .mat-form-field-label {
      margin-top: -0.25rem;
    }
  }
  .mat-button-toggle {
    border: none;
    border-radius: 1.225rem;
    margin-right: 0.875rem;

    .mat-button-toggle-ripple {
      border-radius: inherit;
    }

    &.mat-button-toggle-checked .mat-button-toggle-label-content {
      color: #5e33bf !important;
    }
  }

  mat-button-toggle-group {
    padding-top: 0.24rem;
  }

  .mat-button-toggle-group {
    border: none;
    border-radius: 1.225rem;
    height: 2.45rem;
  }

  .mat-button-toggle-group-appearance-standard {
    .mat-button-toggle-checked:first-child,
    .mat-button-toggle-checked:last-child {
      background-color: #efeaf8;
      border-radius: 1.225rem;
      box-shadow: none;
    }

    .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
      background-color: unset;
    }

    .mat-button-toggle {
      + .mat-button-toggle {
        border: none;
      }
    }

    .mat-button-toggle-appearance-standard {
      background: transparent;
      background-color: #eeeeee;
      border-radius: 1.225rem;
    }
  }
}

app-history {
  ag-grid-angular {
    height: $app-body-height-without-header-footer;
  }
}

app-packages-history {
  ag-grid-angular {
    height: calc(100vh - #{$app-header-height} - 21rem);
  }
}

.custom-dialog-container .mat-dialog-container {
  overflow: hidden;
  padding: 0 !important;
  height: 96%;
}

.search-entry-custom-dialog-container {
  .mat-dialog-container {
    overflow: hidden;
  }
}

.custom-align-actions {
  justify-content: flex-end;
  margin-right: 1.5rem;
  display: flex;
  padding: 0 !important;
  margin-bottom: 1rem !important;
  margin-top: 1rem !important;
}

app-criteria-template {
  .mat-form-field-label {
    color: rgba(0, 0, 0, 0.54);
  }
  .mat-form-field.mat-form-field-can-float:not(.mat-focused)
    .mat-form-field-flex
    .mat-form-field-outline {
    color: #0000008a !important;
  }

  .mat-form-field.mat-form-field-can-float.mat-form-field-disabled
    .mat-form-field-flex
    .mat-form-field-infix
    .mat-form-field-label.mat-form-field-label {
    color: rgba(0, 0, 0, 0.54);
  }
  .mat-form-field.mat-form-field-can-float.mat-form-field-can-float.mat-form-field-should-float:not(.mat-focused)
    .mat-form-field-flex
    .mat-form-field-label {
    color: rgba(0, 0, 0, 0.54);
  }

  .competitors-field .mat-form-field-wrapper {
    padding-bottom: 0;
  }
}

.search-strategy-dialog-panel {
  .mat-dialog-container {
    padding-top: 0.125rem;
  }
}
.rich-text .ql-editor.ql-blank::before {
  color: #0000006b;
}

app-report-editor {
  .padding-on-customize {
    padding: 0 1rem 0 1rem;
  }

  mat-form-field {
    width: 100%;
  }

  app-input-search-strategy {
    .label {
      .mat-form-field-wrapper {
        padding-bottom: 0rem;
        width: 98.5%;
      }
    }
  }
  .mat-form-field-appearance-outline {
    .mat-form-field-infix {
      padding: 0.25rem 0rem 0.75rem 0rem;
      width: 100%;
    }
    .mat-form-field-outline {
      background-color: white;
      border-radius: 0.4rem;
    }
  }

  app-top-threats {
    .mat-checkbox-label {
      white-space: normal;
    }

    .mat-checkbox-inner-container {
      margin-top: 0.3rem;
    }
  }

  .custom-label {
    .mat-form-field-wrapper {
      padding-bottom: 0.8rem;
      padding-top: 0.6rem;
    }
  }

  app-text-area,
  app-text-area-with-boilerplate {
    .ql-toolbar {
      display: none;
      border-bottom: 0rem #e0e0e0 !important;
      border-color: #e0e0e0 !important;
      border-radius: 0.3rem 0.3rem 0rem 0rem;
    }

    .ql-toolbar button {
      stroke: #444;
    }
    .ql-toolbar button:hover {
      background-color: #e0e0e0 !important;
    }

    .ql-toolbar button:active {
      background-color: #5e338f !important;
    }

    .ql-container.ql-snow {
      border-color: #959595;
      border-top: 1px solid #959595 !important;

      border-radius: 0.3rem;
    }
    .ql-editor {
      min-height: 7.5rem !important;
    }

    .ql-container.ql-snow,
    .ql-container.ql-snow:hover {
      background: white;
    }

    .select-template {
      .mat-form-field {
        height: 2.8125rem;
      }
      .mat-form-field-appearance-outline .mat-form-field-outline {
        height: 2.5rem;
      }

      .mat-form-field-appearance-outline .mat-form-field-infix {
        padding: 0.75rem 0rem 0.75rem 0rem !important;
      }
    }

    .mat-form-field-subscript-wrapper {
      position: relative;
    }
    .mat-form-field-appearance-outline .mat-form-field-wrapper {
      height: 3rem;
    }
  }

  app-save-template {
    .mat-form-field-appearance-outline .mat-form-field-label {
      margin-top: -0.5rem;
    }

    .mat-form-field-should-float .mat-form-field-label {
      margin-top: -0.25rem;
    }

    .mat-form-field-invalid .mat-form-field-outline-gap {
      border-width: 1px !important;
    }
  }

  .sections-header {
    .add-icon {
      background: none !important;
    }
  }
}

mat-button-toggle-group {
  overflow: visible !important;

  mat-button-toggle {
    font-weight: 600;
    height: 2rem;
    box-shadow: 0 0 0 1px #e0e0e0;

    &:first-child {
      border-radius: 4px 0 0 4px;
    }

    &:last-child {
      border-radius: 0 4px 4px 0;
    }

    &:hover {
      background-color: #f5f5f5;
    }

    .mat-button-toggle-focus-overlay {
      display: none;
    }

    .mat-button-toggle-label-content {
      height: 2rem;
      line-height: 2rem !important;
      padding: 8px 16px;
    }

    &.mat-button-toggle-disabled {
      background-color: #f8f8f8;
    }

    &.mat-button-toggle-checked {
      background-color: rgba(#5b33bf, 0.1);
      box-shadow: 0 0 0 1px #b29de2;
      color: #5e33bf !important;
      z-index: 999;
      transition: background-color 0.2s ease-in-out;
      transition: box-shadow 0.2s ease-in-out;

      &:first-child {
        border-radius: 4px 0 0 4px;
      }

      &:last-child {
        border-radius: 0 4px 4px 0;
      }

      &:hover {
        background-color: #f5f5f5;
      }
    }
  }
}

.mat-card-header-text {
  margin: 0px !important;
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: $clarivate-0;
}

app-toggle-format {
  .mat-button-toggle-button {
    width: 8rem;
  }
}

app-custom-dropzone-preview {
  max-width: unset !important;
  min-width: unset !important;
  padding: 0 !important;
  flex-wrap: wrap;
  height: auto !important;
  margin: 0 auto !important;
}

.ql-editor.ql-blank::before {
  font-style: normal !important;
  color: rgba(0, 0, 0, 0.42);
}

app-report-editor {
  mat-dialog-content {
    padding: 0 0 0 24px !important;
    padding-left: 0 !important;
  }
  mat-dialog-actions {
    justify-content: space-between !important;
    padding: 16px 0 16px 0 !important;
  }
}

app-goods-similarity-panel {
  mat-card {
    height: 60vh;
    left: 0;
    padding: 0 !important;
    position: absolute;
    right: 0;
    margin-top: 1.25rem;
  }
}

.breadcrumb-product-menu {
  margin-top: 0.35rem;
}

.price-overview-menu {
  min-width: 155px !important;
  position: relative;
  background: transparent;
  box-shadow: none;
  top: 1.2rem;

  &.mat-menu-before {
    left: 3.3rem;
  }

  &.mat-menu-after {
    left: -3.3rem;
  }

  .container {
    background-color: white;
    box-shadow: 0 2px 1px -1px #0003, 0 1px 1px #00000024, 0 1px 3px #0000001f;
    padding: 1rem;
    margin: 1rem;

    .title {
      display: block;
      font-size: 1.05rem;
      font-weight: bold;
      margin-bottom: 0.875rem;
    }

    .row-title {
      display: block;
      font-size: 0.875rem;
    }

    .row-value {
      display: block;
      font-size: 0.875rem;
      font-weight: bold;
      margin-bottom: 0.438rem;
    }

    .price-overview-link {
      margin-top: 1rem;
      color: #5e33bf;
      cursor: pointer;
      font-weight: normal;

      &:hover {
        text-decoration: underline;
      }
    }

    &::after {
      background-color: inherit;
      box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.116);
      content: '';
      height: 10px;
      left: 50%;
      position: absolute;
      bottom: 20px;
      transform: translateX(-50%) rotateZ(45deg);
      width: 10px;
    }
  }
}

.top-threats {
  &.actions {
    .view-all-button {
      width: 80%;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right: 0px;
    }

    .arrow-button {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      padding: 0px;
      margin: 0px;
      width: 10px;
      min-width: 37px;
    }
  }
}

app-threat {
  lib-goods-and-services-descriptions .class-with-matches-container {
    width: 100% !important;
  }
}

.in-use-time {
  max-width: 100% !important;
}

app-market-multiselect-autocomplete {
  .mat-chip-list-wrapper {
    display: block;
  }
}

.market-description {
  max-width: 27rem !important;
}

.market-name {
  display: inline-block;
  width: 5vw;
}

.ag-header-group-cell-no-group {
  &:hover {
    background-color: transparent !important;
  }
}

lib-geographic-presence-risk-panel {
  .mat-card-header {
    width: calc(100% - 5.5rem);
  }
}

app-goods-case-law-prediction-score {
  mat-card-content {
    display: block !important;
  }
}

.all-threats-goods-and-services {
  white-space: nowrap !important;
}

app-goods-and-services-dialog {
  .mat-dialog-content {
    height: 100%;
    max-height: unset;
  }
}

app-identical-matches-dialog {
  strong {
    color: $exact-risk;
  }
}

.poca-score-tooltip {
  white-space: pre-line;
}

lib-in-use-deep-dive {
  display: flex;
  height: calc(100vh - 4rem) !important;
  position: relative;
}

lib-case-law-prediction-score-risk-panel,
app-goods-case-law-prediction-score {
  mat-card {
    min-height: 144px;
  }
}

.pto-record {
  .ag-menu-option-text::after {
    content: url(/assets/img/open_in_new.png);
    margin-left: 0.219rem;
    position: relative;
    top: 5px;
  }
}

app-all-threats-with-bulk-actions .circle,
app-content-threats-table .circle {
  margin-top: -11px;
}

app-goods-and-services-field,
app-autocomplete-input,
app-market-multiselect-autocomplete {
  mat-form-field {
    &:not(.mat-focused) {
      .mat-form-field-label.mat-empty {
        line-height: 2rem;
      }
    }
  }

  .mat-form-field-invalid {
    .mat-form-field-outline {
      .mat-form-field-outline-gap {
        border-width: 1px;
      }
    }
  }
}

app-goods-and-services-field {
  .mat-input-element {
    width: calc(100% - 0.5rem) !important;
  }
  .mat-form-field-appearance-outline .mat-form-field-flex {
    padding: 0 1rem 0 1rem;
  }
}

app-application-header {
  .search-entry {
    color: white !important;

    app-notification-dropdown {
      .cdx-but-menu:hover {
        background-color: #ffffff3d !important;
      }
    }

    .breadcrumb-label {
      color: white !important;
    }

    mat-icon {
      color: white !important;
    }
  }
}

.price-actions {
  mat-button-toggle {
    height: 3.5rem !important;
  }

  .mat-button-toggle-button {
    height: 3.5rem !important;
  }

  .mat-form-field-wrapper {
    padding-bottom: 0rem !important;
  }

  .mat-form-field-appearance-outline .mat-form-field-infix {
    padding: 1.25rem 0rem 1.25rem 0rem !important;
  }
}

.select-template {
  .mat-form-field {
    height: 61px;
  }
  .mat-form-field-flex {
    .mat-select-arrow {
      position: relative;
      top: 0.2rem;
    }
    .mat-select.mat-select-invalid .mat-select-arrow {
      color: black !important;
    }
  }
  .mat-form-field-infix {
    border-top: 0.263rem solid transparent !important;
    height: 3.625rem !important;
  }
  .mat-form-field-appearance-outline .mat-form-field-infix {
    border-top: 0 !important;
    padding: 1.25rem 0rem 1.25rem 0rem !important;
  }

  .mat-form-field-appearance-outline .mat-form-field-outline {
    top: 0 !important;
  }
}

app-search-entry {
  mat-card-header {
    &.with-count {
      width: 100%;

      .mat-card-header-text {
        width: 100%;

        mat-card-title {
          display: flex;
          justify-content: space-between;
          width: 100%;
        }
      }
    }
  }

  .select-template .mat-form-field-wrapper {
    padding-bottom: 0 !important;
    width: 28rem;
  }

  .mat-form-field-appearance-outline .mat-form-field-outline {
    top: 0 !important;
  }
}

app-competitors-and-owner-fields {
  .companies .mat-form-field-wrapper {
    padding-bottom: 0 !important;
  }

  input {
    padding-left: 0 !important;
  }

  .mat-form-field-appearance-outline .mat-form-field-flex {
    padding: 0 1rem 0 1rem;
  }
}

app-candidate-name-field {
  .candidate-name-input .mat-form-field-wrapper {
    border-radius: 2px;
  }

  .candidate-name-input .mat-form-field-infix {
    height: 71px !important;
    top: -3px;
  }

  .mat-form-field-appearance-outline .mat-form-field-flex {
    padding: 0 1rem 0 1rem;
  }

  .mat-input-element {
    line-height: 2rem;
  }
}

gridstack {
  background-color: #fafafa !important;
  min-width: 1500px !important;
  margin: 0 auto;

  .grid-stack-item-content {
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
      0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);

    lib-heat-map {
      padding: 0 1rem;
    }
  }

  &.fixed {
    overflow: unset !important;
    padding: 1rem;
  }

  app-top-threats {
    margin-top: 0px !important;
  }

  app-all-threats-insights {
    lib-heat-map {
      display: flex;
      flex-direction: column;
      padding: 0 3rem 0 3.35rem !important;

      .description {
        display: flex;
        flex-direction: row;
        margin-top: 1.5rem !important;
        margin-left: 0 !important;
      }
      .heatmap {
        margin: 0 auto;

        .body {
          border: none !important;
          padding: 0 !important;
        }

        .goods-and-services-title h4 {
          margin-left: -4rem !important;
        }

        .header {
          display: none !important;
        }
      }
    }
  }
}

.ag-standard-button.ag-filter-apply-panel-button {
  margin-left: 0.75rem !important;
}

.cdk-overlay-pane.add-widget {
  mat-dialog-container {
    padding: 16px 0 0 0;

    .mat-dialog-title {
      border-bottom: 1px solid #e0e0e0;
      padding: 0 1rem 1rem 1rem;
    }
  }
}

.widget-menu {
  max-height: 48px !important;
  min-height: 48px !important;

  .mat-menu-content {
    padding: 0 !important;
  }

  .widget-actions-menu-item {
    background-color: #fff !important;
    &:hover {
      background-color: #eee !important;
    }
  }
}

app-owner-search {
  .ag-root-wrapper-body.ag-layout-normal {
    height: auto;
  }
  .ag-row-group {
    background-color: transparent !important;
  }

  .ag-theme-cdx {
    --ag-selected-row-background-color: transparent;
  }

  .ag-theme-cdx .ag-checkbox-input-wrapper:not(.ag-checked)::after {
    color: #5e33bf !important;
  }
}

app-coexistence-per-market-widget,
app-risk-per-market-widget {
  header {
    margin-bottom: 0 !important;
  }
}

.cdk-overlay-pane {
  .filter-content {
    padding: 1rem;
  }

  .all-threats-custom-filter-menu.mat-menu-panel {
    padding: 0;
    max-height: 37rem;
    .mat-menu-content {
      padding: 0 0 1rem 0 !important;
    }
  }

  .markets-filter-menu {
    width: 235px;

    .mat-form-field:last-of-type {
      .mat-form-field-wrapper {
        padding-top: 0.2rem !important;
        padding-bottom: 1rem !important;
      }

      .mat-form-field-infix {
        padding: 0.4rem 0 0.5rem 0;
      }
    }

    .mat-form-field-wrapper {
      padding-top: 0.2rem !important;
      padding-bottom: 0.5rem !important;
    }

    .mat-form-field {
      &:not(.mat-focused) {
        .mat-form-field-label-wrapper {
        }
      }
    }

    .mat-form-field-infix {
      padding: 0.4rem 0 0.5rem 0;
    }
  }

  .classes-filter-menu {
    width: 150px !important;
  }
}

app-owner-results {
  .ag-root-wrapper-body.ag-layout-normal {
    height: calc(100vh - 14.1rem);
  }
}

a:focus-visible:not(.mat-button-base) {
  outline-color: $clarivate-0;
}

app-wcl-results {
  .cdk-keyboard-focused .mat-focus-indicator::before,
  .cdk-keyboard-focused .mat-focus-indicator::after {
    border-color: transparent;
    box-shadow: none;
  }
}

app-owner-globality,
app-litigations-widget,
app-portfolio-size-widget {
  lib-risk-measure {
    .container {
      .header {
        display: none !important;
      }
    }
  }
}

app-owner-filter {
  overflow-x: hidden !important;

  .mat-tree {
    width: 21.5rem;
  }
  .mat-checkbox-layout {
    white-space: normal !important;
  }

  .mat-checkbox-inner-container {
    margin-top: 4px;
    margin: right 16px;
  }

  .mat-tree-node {
    align-items: normal;
    min-height: 1rem;
    padding: 0.5rem 1rem 0.5rem 0rem;

    .mat-icon-button {
      height: 1.5rem;
      line-height: 0rem;
      margin: 0rem 0.5rem 0rem 0.5rem;
      width: 1.5rem;
    }
  }
}

app-owner-insights {
  gridstack {
    min-width: 1300px !important;
  }
}

app-owners-deep-dive {
  lib-owner-deep-dive {
    height: calc(100% - 2.55rem);
    overflow: auto;
    .right {
      h2 {
        display: none !important;
      }
      mat-card {
        height: calc(100% - 0.1rem) !important;
      }
    }
  }
}

app-litigations-deep-dive {
  lib-litigation-deep-dive {
    .right {
      h2 {
        display: none !important;
      }
    }
  }
}

.all-threats-custom-filter-menu {
  max-width: 45rem !important;
  width: 45rem !important;

  .heatmap {
    .body {
      border: unset !important;
    }
  }
}

app-filter-templates {
  .mat-form-field-appearance-outline .mat-form-field-outline {
    height: 2.5rem;
  }

  .mat-form-field-appearance-outline .mat-form-field-flex {
    padding: 0 0.75em 0 0.75em;
    position: relative;
    border: #9e9e9e;
  }

  .mat-form-field-appearance-outline .mat-form-field-infix {
    padding: 0.35rem 0 1em 0;
  }

  .mat-select-value {
    overflow: visible;
  }
}

.flag-filter {
  .mat-checkbox-label {
    align-items: center;
    display: flex;
    height: 1.5rem;
    justify-content: flex-start;
  }
}

.filter-container .dropdown-style {
  margin-top: 4px;
}

app-flags-table,
app-flags {
  .round-button {
    &:focus-visible {
      box-shadow: 0 0 0 2px white, 0 0 0 4px #5e33bf;
    }

    &:hover {
      background-color: var(--hover-color) !important;
      box-shadow: 0 0 0 2px #00000014, 0 0 0 4px #00000014;
      border: none !important;
    }
  }
}

.xng-breadcrumb-root {
  height: 100%;

  .xng-breadcrumb-list {
    height: 100%;
  }
}

.xng-breadcrumb-link {
  mat-icon {
    align-items: center;
    display: flex;
    position: relative;
  }
}

a.xng-breadcrumb-link:hover {
  text-decoration: none !important;
}

.xng-breadcrumb-separator {
  display: block !important;
}

app-breadcrumb {
  .icon-container {
    top: auto !important;
  }
}

app-clarivate-header {
  mat-icon {
    position: relative;
    bottom: 0.25rem;
  }
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: $clarivate-0;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle {
  color: $clarivate-0;
  background-color: $clarivate-0;
}

.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
  background-color: $clarivate-0;
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent
  .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent
  .mat-ripple-element {
  background-color: $clarivate-0 !important;
}

app-save-filter-dialog {
  .mat-form-field.mat-form-field-invalid {
    .mat-form-field-outline-gap {
      border-bottom: 1px solid red !important;
    }
  }
}

app-edit-filter-dialog {
  .mat-form-field.mat-form-field-invalid {
    .mat-form-field-outline-gap {
      border-bottom: 1px solid red !important;
    }
  }
}

lib-threat-comment {
  .mat-badge-content {
    transform: none !important;
  }
}

app-bulk-action-menu {
  .mat-icon-button {
    line-height: unset !important;
  }
}
.letter-icon {
  background-color: #001750;
  border-radius: 50%;
  color: white;
  font-family: Open Sans;
  font-size: 0.625rem !important;
  font-weight: bold;
  height: 1rem !important;
  left: 0.25rem;
  padding-top: 0.1875rem;
  padding-left: 0.26rem !important;
  position: relative;
  width: 1rem !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

app-classes-filter {
  .mat-checkbox-label {
    padding-left: 0.25rem;
  }
}

.btn {
  border: none;
  transition: background-color 0.3s, color 0.3s;
}

.btn:hover {
  background-color: #00000014;
}

.btn:active {
  outline: 0.125rem solid $clarivate-0;
  opacity: 1;
}

.btn:focus {
  outline: none;
}

.btn:focus-visible {
  outline: 0.125rem solid #5e33bf;
}

.mat-tab-body-content {
  overflow-x: hidden !important;
}

app-save-freetext-renderer {
  .content {
    line-height: 0 !important;

    .mat-form-field-wrapper {
      padding-bottom: 4px !important;

      .mat-input-element {
        margin-top: -0.5em;
      }
    }
  }
}

lib-in-use-deep-dive {
  h2 {
    font-size: 1.25rem;
    margin-right: 0.5rem !important;
  }
}

span.mat-ripple.mat-slide-toggle-ripple.mat-focus-indicator:before {
  border-color: transparent;
}

span.mat-ripple.mat-checkbox-ripple.mat-focus-indicator::before {
  border-color: transparent;
}

app-feature-level-selection {
  mat-button-toggle.mat-button-toggle-disabled:not(.mat-button-toggle-checked) {
    background-color: #ffffff !important;
    color: #e0e0e0 !important;
  }
}

.custom-divider {
  padding-bottom: 1rem !important;
}

.cdk-keyboard-focused .mat-focus-indicator::before,
.cdk-keyboard-focused .cdx-footer a:focus:not(.mat-button-base)::before,
.cdx-footer .cdk-keyboard-focused a:focus:not(.mat-button-base)::before {
  border-color: #5e33bf;
}

app-domain-names-fulltext-view {
  width: 100% !important;
}

.mat-form-field-appearance-outline .mat-form-field-outline {
  color: #959595;
}

.mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: #000000de;
}

.flag-name input.mat-input-element {
  margin-top: 0;
  padding-bottom: 2px;
}

.ql-active {
  svg {
    background-color: #5b33bf !important;
    stroke: #ffffff !important;
  }
  .ql-stroke {
    stroke: #ffffff !important;
    fill: #5b33bf;
  }
}

app-report-editor .ql-snow.ql-toolbar button:hover .ql-stroke {
  stroke: #444;
}

app-select-all-checkbox-grouped-rows {
  .mat-checkbox .mat-checkbox-inner-container {
    font-weight: bold;
    height: 1.125rem !important;
    margin-left: 2.25rem;
    width: 1.125rem !important;
  }
  .mat-checkbox-checkmark-path {
    stroke-width: 3px !important;
  }
}

.mat-focus-indicator.mat-raised-button.mat-button-base.mat-primary {
  box-shadow: none !important;
}

.grid-stack .grid-stack-item .grid-stack-item-content {
  height: 97.5%;
}

.report-editor-disabled-div {
  pointer-events: none;
  opacity: 0.3;
}

.report-editor-highlight-div {
}

.report-editor-highlight-div:hover {
  background-color: $ocean-blue-hover;
  cursor: pointer;
}

.report-editor-row-content {
  display: flex;
  .left {
    width: 95%;
    padding: 0 1rem;
  }
  .right {
    padding: 1.33rem 0.5rem 1rem 0rem;
  }
}

.save-filter-select {
  .mat-option.mat-selected:not(.mat-option-disabled) {
    color: black !important;
  }

  .option-container {
    &:hover {
      .option-buttons {
        opacity: 1;
      }
    }

    .option-buttons {
      opacity: 0;
    }
  }
}

#optionalCriteria {
  .mat-slide-toggle-content {
    margin-left: 0.5rem;
  }
}

#candidateImage {
  mat-card-header {
    margin-bottom: 1rem;
  }

  app-custom-dropzone-preview {
    height: 100% !important;
  }
}

app-all-threats-with-bulk-actions .mat-checkbox-layout .mat-checkbox-label {
  line-height: 32px;
}

app-report-editor .mat-checkbox-layout {
  padding-top: 8px !important;
  padding-left: 12px !important;
}

app-flag-definitions-dialog {
  .mat-form-field-appearance-outline .mat-form-field-infix {
    padding: 1em 0 1em 0;
    top: -2px;
  }
}

.report-editor-dialog-conatiner mat-dialog-container {
  overflow: hidden;
  padding: 0rem 1.5rem 1.5rem 1.5rem;
}

app-goods-and-services-field {
  .mat-form-field-appearance-outline .mat-form-field-infix {
    top: -3px;
  }
}

app-market-multiselect-autocomplete {
  .mat-form-field-appearance-outline .mat-form-field-infix {
    top: -3px;
  }
}

app-brandlandscape-admin .ag-row-group {
  background-color: transparent !important;
}

.cdx-footer__copyright {
  color: transparent;
  position: relative;
  &::after {
    content: '© 2025 Clarivate';
    color: #fff;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.cdk-virtual-scroll-content-wrapper {
  box-sizing: border-box;
  display: grid;
  grid-column-gap: 0.5rem;
  grid-row-gap: 0.5rem;
  padding: 1rem;
}

app-content-threats-table {
  .fullscreen {
    app-tile-view {
      height: 94.5vh;
    }
  }
}

.coexistence-per-market-dialog mat-dialog-container {
  overflow-x: hidden;
}

.risk-per-market-dialog mat-dialog-container {
  overflow-x: hidden;
}
