$app-header-height: 5rem;
$app-footer-height: 4.069rem;
$app-body-height-without-header: calc(100vh - #{$app-header-height});
$app-body-height-without-header-footer: calc(
  101vh - #{$app-header-height} - #{$app-footer-height}
);

$initiate-chip-list-max-height-1400px: 17.5rem;
$initiate-chip-list-max-height-920px: 35rem;

$initiate-mat-form-field-min-width-1400px: 28rem;
$initiate-mat-form-field-min-width-1150px: 17.5rem;
