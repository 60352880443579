@use 'sass:map';
@use '@angular/material' as mat;
@use '@cdx/theme-angular-material' as cdx;

$color-config: mat.get-color-config(cdx.$cdx-theme);
$primary-palette: map.get($color-config, 'primary');
$accent-palette: map.get($color-config, 'accent');
$warn-palette: map.get($color-config, 'warn');

.mat-snack-bar-container {
  /* stylelint-disable-next-line */
  padding: 0 !important;
}

.mat-simple-snackbar {
  padding: 0.875rem 1rem;
}

.cdx-snackbar-toast-error,
.cdx-snackbar-toast-info,
.cdx-snackbar-toast-success,
.cdx-snackbar-toast-warning {
  border-radius: 0.25rem;
  display: flex;

  .cdx-snackbar-toast-icon {
    align-items: center;
    display: flex;

    .cdx-snackbar-toast-icon_style {
      padding: 0.5rem;
      .cdx-snackbar-toast-icon_color {
        color: white;
      }
    }
  }

  .cdx-snackbar-toast-message {
    align-items: center;
    display: flex;
    flex: 1;
    padding: 6px 8px 6px 16px;
  }

  .cdx-snackbar-toast-button {
    align-items: center;
    display: flex;
    padding: 6px;

    button {
      line-height: 1rem;
      max-width: 10rem;
      white-space: pre-wrap;
    }

    .mat-button-wrapper {
      color: #175897;
      font-weight: bold;
    }
  }
}

$cdx-color-error-light: mat.get-color-from-palette($warn-palette, 100);
$cdx-color-error-base: mat.get-color-from-palette($warn-palette, 600);
$cdx-color-error-dark: mat.get-color-from-palette($warn-palette, 900);

//TODO waiting for fransisco's reply...
.cdx-snackbar-toast-info {
  background-color: #e2f5fe;
  border: 1px solid #1d78b9;
  color: #175897;

  .cdx-snackbar-toast-icon {
    background-color: #1d78b9;
  }
}

.cdx-snackbar-toast-error {
  background-color: $cdx-color-error-light;
  border: 1px solid $cdx-color-error-base;
  color: $cdx-color-error-dark;

  .cdx-snackbar-toast-icon {
    background-color: $cdx-color-error-base;
  }
  /* stylelint-disable-next-line */
  .mat-button-wrapper {
    color: $cdx-color-error-dark;
  }
}

$cdx-color-success-light: mat.get-color-from-palette($accent-palette, 100);
$cdx-color-success-base: mat.get-color-from-palette($accent-palette, 600);
$cdx-color-success-dark: mat.get-color-from-palette($accent-palette, 900);

.cdx-snackbar-toast-success {
  background-color: $cdx-color-success-light;
  border: 1px solid $cdx-color-success-base;
  color: $cdx-color-success-dark;

  .cdx-snackbar-toast-icon {
    background-color: $cdx-color-success-base;
  }
  /* stylelint-disable-next-line */
  .mat-button-wrapper {
    color: $cdx-color-success-dark;
  }
}
