@import '~@angular/material/theming';

// Custom CDX Material Design palettes (Optional)
// Contains the $cdx-primary, $cdx-accent, $cdx-warn palettes.
@import '~@cdx/styles/cdx-theming';

@import '_snackbar_theme.scss';

// Add custom $cdx-primary, $cdx-accent, $cdx-warn overrides here
// $cdx-primary: (...)
// $cdx-accent: (..)
// $cdx-warn: (..)

// Use default CDX typography
@import '~@cdx/styles/settings/typography';

// Define a custom typography config that overrides the font-family
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i&display=swap');
$app-typography: mat-typography-config(
  $font-family: 'Open Sans',
);
@include mat-core($app-typography);

// Use the CDX palettes in cdx-theming.scss (imported above). Or define the palettes for your custom
// themes based on the examples. For each palette, you can optionally specify a default, lighter,
// and darker hue. Available color palettes: https://material.io/design/color/
$cdx-palette-primary: mat-palette($cdx-primary);
$cdx-palette-accent: mat-palette($cdx-accent);

// The warn palette is optional (defaults to red).
$cdx-palette-warn: mat-palette($cdx-warn);

// Create the theme object (a Sass map containing all of the palettes).
$cdx-app-theme: mat-light-theme(
  $cdx-palette-primary,
  $cdx-palette-accent,
  $cdx-palette-warn
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($cdx-app-theme);

// Create aliases to use within your app (Do not override the `$cdx-palette-*` properties)
$bla-palette-primary: $cdx-palette-primary;
$bla-palette-accent: $cdx-palette-accent;
$bla-palette-warn: $cdx-palette-warn;

// The angular/material mixin `mat-color($palette, $hue: default, $opacity: null)` returns a color
// based on the values for hue, and opacity, this may be used in other stylesheets.
// e.g. `background-color: mat-color($cdx-palette-primary, 900);` sets the background-color to the
// darkest hue.

@import '~@cdx/styles/cdx-core';

//// parts of the theme that aren't changed with mixins/functions
//// TODO ask CDX team if the pref is this file or styles.scss
//html {
//  /* stylelint-disable-next-line unit-disallowed-list */
//  font-size: 14px;
//}

body {
  font-family: mat-font-family($app-typography);
  font-size: 0.875rem;
}

@import '~@ag-grid-community/core/dist/styles/ag-grid.scss';
@import '~@ag-grid-community/core/dist/styles/ag-theme-material/sass/ag-theme-material-mixin';

.ag-theme-cdx {
  @include ag-theme-material(
    (
      font-family: mat-font-family($app-typography),
      font-size: 12.25px !important,
      grid-size: 6px,
      header-background-color: $cdx-color-neutral-50,
      header-foreground-color: $cdx-color-neutral-900,
      material-accent-color: mat-color($bla-palette-accent),
      material-primary-color: mat-color($bla-palette-primary),
      range-selection-background-color:
        ag-derived(material-primary-color, $opacity: 0.1),
      row-hover-color: ag-derived(material-primary-color, $opacity: 0.05),
      selected-row-background-color:
        ag-derived(material-primary-color, $opacity: 0.1),
      subheader-background-color: $cdx-color-neutral-100,
      value-change-delta-down-color: $cdx-color-warning-base,
      value-change-delta-up-color: $cdx-color-success-base,
    )
  );

  .ag-checkbox-input-wrapper.ag-checked::after {
    color: $cdx-color-primary-base;
  }
}
